<template>
  <b-card class="w-100">
    <b-card-text
      class="text-right mt-0 cursor-pointer"
      @click="$router.push({ name: 'client-home' })"
    >
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Abort</span>
    </b-card-text>

    <form-wizard
      :color="'#459042'"
      :step-size="'xs'"
      :title="title"
      subtitle=""
      back-button-text="Prev"
      @on-complete="onComplete"
    >
      <tab-content
        v-for="(custom_field, index) in customFieldsWithActiveQuestions"
        :key="index"
        :title="custom_field.title"
        :before-change="() => beforeStepChange(index)"
      >
        <div class="mb-3 mt-1">
          <h3>{{ custom_field.title }}</h3>
          <p class="text-muted">
            {{ custom_field.title.description }}
          </p>
          <hr />
        </div>
        <validation-observer ref="customFieldsForm">
          <b-form>
            <b-form-group
              v-for="(field, index2) in getActiveFields((custom_field))"
              :key="index2"
              :label-for="`cfield-${index2}`"
              label-cols-sm="4"
              label-cols-lg="4"
              content-col-sm
              content-cols-lg-7
            >
              <template #label>
                <span>{{ field.title }} <sup v-if="field.required_field" style="color: tomato">*</sup></span>
              </template>
              <validation-provider
                #default="{ errors }"
                :name="field.title"
                :rules="field.field_type === 'file' && getFieldResponse(field) ? '' : field.required_field ? 'required' : ''"
              >
                <b-form-input
                  v-if="['text', 'number'].includes(field.field_type)"
                  :id="`cfield-${index2}`"
                  :value="getFieldResponse(field)"
                  :placeholder="field.placeholder"
                  @input="setFieldResponse(field, $event)"
                />
                <b-form-textarea
                  v-if="field.field_type === 'text-area'"
                  :id="`cfield-${index2}`"
                  :value="getFieldResponse(field)"
                  :placeholder="field.placeholder"
                  @input="setFieldResponse(field, $event)"
                />
                <v-select
                  v-if="field.field_type === 'select'"
                  :id="`cfield-${index2}`"
                  :value="getFieldResponse(field)"
                  :placeholder="field.placeholder"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="field.field_options_if_type_is_select"
                  :clearable="field.required_field"
                  @change="setFieldResponse(field, $event)"
                  @input="setFieldResponse(field, $event)"
                />
                <v-select
                  v-if="field.field_type === 'trustees'"
                  :id="`cfield-${index2}`"
                  :value="getFieldResponse(field)"
                  :placeholder="field.placeholder"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="dynamic_field_options.trustees"
                  :clearable="field.required_field"
                  :reduce="val => val.value"
                  @change="setFieldResponse(field, $event)"
                  @input="setFieldResponse(field, $event)"
                />
                <div v-if="field.field_type === 'file' && getFieldResponse(field)">
                  <small class="text-muted d-inline-block my-1">
                    <b-img :src="getFieldResponse(field)" />
                    <a :href="getFieldResponse(field)" target="_blank">
                      Download Attachment
                    </a>
                  </small>
                  <b-form-file
                    accept="text/csv,.pdf,.jpg,.jpeg,.png"
                    no-drop
                    @input="setFieldResponse(field, $event)"
                  />
                </div>
                <b-form-file
                  v-else-if="field.field_type === 'file'"
                  accept="text/csv,.pdf,.jpg,.jpeg,.png"
                  no-drop
                   :value="getFieldResponse(field)"
                   @input="setFieldResponse(field, $event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </b-card>
</template>

<script>


import {
  BCard,
  BForm,
  BImg,
  BCardText,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";

import vSelect from "vue-select";

import { get } from "lodash";
import { required } from "@validations";
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    BCard,
    BForm,
    BImg,
    BCardText,
    BFormFile,
    FormWizard,
    TabContent,
    BFormGroup,
    BFormInput,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    title: {
      type: String,
      default: "Custom Fields Filling and Stuff",
    },
    loanProduct: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
      answers: {},
    };
  },
  computed: {
    dynamic_field_options() {
      return this.getValueFromSource(
        this.loanProduct, 
        "dynamic_field_options", 
        { trustees: [] }
      )
    },
    customFields() {
      return this.getValueFromSource(this.loanProduct, 'custom_fields', [])
    },
    customFieldAnswers() {
      return this.getValueFromSource(this.loanProduct, 'custom_field_answers', []);
    },
    customFieldsWithActiveQuestions() {
      return this.customFields.filter(
        customField => customField.fields.filter(field => field.status === "active" && field.one_time_only).length > 0
      )
    }
  },
  methods: {
    getActiveFields(custom_field) {
      const { fields = [] } = custom_field;
      const activeFields = fields.filter((field) => field.status === "active" && field.one_time_only);
      const sortRequiredFieldsFirst = activeFields.sort(field => !field.required_field);

      return sortRequiredFieldsFirst
    },
    findResponseFromPastResponses(custom_field_id){
      const found_custom_field = this.customFieldAnswers.find(cField => cField.custom_field_id === custom_field_id)
      // console.log("found_custom_field", found_custom_field)
      if (found_custom_field){
        return found_custom_field.answer.value
      }

      return false;
    },
    setFieldResponse(field, value) {
      this.answers[field._id] = value;
    },
    getFieldResponse(field) {
      if (this.answers[field._id]) {
        return this.answers[field._id];
      }
      const answer = this.findResponseFromPastResponses(field._id);
      if (answer){
        this.answers[field._id] = answer;
      }

      return this.answers[field._id];
    },
    async beforeStepChange(index) {
      const formRef = this.$refs.customFieldsForm[index];
      if (formRef) {
        const isValid = await formRef.validate();
        return isValid;
      }
      return false;
    },
    async onComplete() {
      const numberOfRefs = this.$refs.customFieldsForm.length;
      const formRef = this.$refs.customFieldsForm[numberOfRefs - 1];
      const isValid = await formRef.validate();
      if (isValid) {
        this.submitAnswersToCustomFields();
      }
    },
    async submitAnswersToCustomFields() {
      try {
        this.loading = true;

        const formData = new FormData();

        Object.entries(this.answers).forEach((entry) => {
          const [custom_field_id, answer] = entry;
          formData.append(custom_field_id, answer)
        })

        await this.useJwt().clientService.submitClientGroupCustomFieldAnswers(
          this.loanProduct._id,
          formData
        );

        this.$emit("finish")
        // this.$router.push({ name: "client-new-loan", params: { loan_product_id: this.loanProduct._id } }).catch(() => {});

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Product Onboarding Complete",
            icon: "CheckCircleIcon",
            variant: "success",
          },
        });
      } catch (error) {
        const error_message =
          get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
