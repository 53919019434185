<template>
<b-overlay :show="loading">
  <section class="wrapper vh-100">
    <div></div>
    <div>
      <div class="d-flex justify-content-center">
        <div class="mt-3">
          <h1 class="display-4">
            <strong> {{ getValueFromSource(loan_product, 'title') }} </strong> Application Overview
          </h1>
          <small>{{ getValueFromSource(loan_product, 'description') }}</small>
        </div>
      </div>
    </div>
    <section class="h-100 d-md-flex w-100  align-items-center" style="flex: 1 1 auto">
      <div class="mr-3">
        <!-- first step-->
        <div class="mb-2">
          <div class="cursor-pointer">
            <div class=" ">
              <div class="mr-2">
                <feather-icon v-if="completed_product_onboarding" icon="CheckCircleIcon" size="36" class="text-secondary" />
                <feather-icon v-else icon="AlertCircleIcon" size="36" class="text-danger" />
              </div>
              <div>
                <h1 class="">
                  OnBoarding
                </h1>
                <p class="lead">
                  We will collect relevant data required to evaluate you and the loan request
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- second step-->
        <div class="mb-2">
          <div class="cursor-pointer">
            <div class=" ">
              <div class="mr-2">
                <feather-icon v-if="completed_data_verification && !mute_verification_option" icon="CheckCircleIcon" :class="mute_verification_option" size="36" class="text-secondary" />
                <feather-icon v-else icon="AlertCircleIcon" size="36" :class="mute_verification_option" class="text-danger" />

              </div>
              <div>
                <h1 class="" :class="mute_verification_option">
                  Verification Process
                </h1>
                <p class="lead" :class="mute_verification_option">
                  Our systems will undergo a thorough evaluation process based on your data <br />
                  gathered
                  with an assessment
                  on
                  your credit score to conclude
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- third step-->
        <div class="mb-2">
          <div class="cursor-pointer">
            <div class=" ">
              <div class="mr-2">
                <feather-icon v-if="completed_data_verification && !mute_request_loan_option" icon="CheckCircleIcon" size="36" class="text-secondary" :class="mute_request_loan_option" />
                <feather-icon v-else icon="AlertCircleIcon" size="36" class="text-secondary" :class="mute_request_loan_option" />
              </div>
              <div>
                <h1 class="" :class="mute_request_loan_option">
                  Request Loan
                </h1>
                <p :class="mute_request_loan_option">
                  Make loan request
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="flex-fill">
        <div class="">
          <div>
            <!-- OnBoarding-->
            <div v-if="!completed_product_onboarding">
              <div>
                <CustomFields v-if="loan_product" :title="getValueFromSource(loan_product, 'title')" :loan-product="loan_product" @finish="finishOnBoarding" />
              </div>
            </div>

            <!--Currently under review -->
            <div v-if="!completed_data_verification && completed_product_onboarding">
              <b-card>
                <div>
                  <div class="d-flex align-items-center justify-content-center">
                    <div class="text-center">
                      <h4 class="mt-3">
                        Reviewing
                      </h4>
                      <div class="pending-gif">
                        <img class=" text-center" src="@/assets/lottie-files/pending.gif" height="200" width="200" alt="pending gif" />
                      </div>
                      <div class="transparent-pending-gif">
                        <img class=" text-center" src="@/assets/lottie-files/pending-transparent.gif" height="200" width="200" alt="transparent-pending-gif" />
                      </div>
                      <p class="text-center text-warning">
                        Your data is currently been reviewed, You will be able to apply for loan as
                        soon as the review is complete
                      </p>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>

            <!-- Proceed with Loan request card -->
            <div v-if="completed_data_verification && completed_product_onboarding">
              <b-card>
                <div class="d-flex align-items-center justify-content-center">
                  <div>
                    <h1 class="text-center">
                      All Good !
                    </h1>
                    <p>
                      Proceed with loan request
                    </p>
                    <div class="text-center">
                      <b-link :to="{ name: 'client-new-loan' }">
                        <b-button variant="primary">
                          Request Loan
                        </b-button>
                      </b-link>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div class="mt-4">
            <div class="d-flex justify-content-between">
              <div>
                <b-link :to="{ name: 'client-select-loan-product'}">
                  <b-button variant="primary">
                    Return
                  </b-button>
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </section>
</b-overlay>
</template>

<script>
import CustomFields from '@/pages/client/loans/new/loan-product-custom-fields/CustomFields.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BButton,
  BCard,
  BImg,
  BLink,
  BOverlay
} from "bootstrap-vue";
import {
  get
} from "lodash";

export default {
  name: "LoanRequestOverview",
  components: {
    BButton,
    BLink,
    BCard,
    BImg,
    BOverlay,
    CustomFields
  },
  data() {
    return {
      completed_product_onboarding: false,
      completed_data_verification: false,
      loan_product: null,
      loading: false
    }
  },
  computed: {
    loan_product_id() {
      const {
        loan_product_id
      } = this.$route.params
      return loan_product_id
    },
    mute_verification_option() {
      return this.completed_product_onboarding ? '' : 'text-muted';
    },
    mute_request_loan_option() {
      return this.completed_product_onboarding && this.completed_data_verification ? '' : 'text-muted';
    }
  },
  async created() {
    await this.canRequestLoanProduct();
    this.fetchLoanProduct().then(async () => {
      if (this.loan_product && this.loan_product.specification !== 'nss') {
        await this.isUserDataVerified();
      } else {
        this.completed_data_verification = true
      }
    });

    //  redirect user to loan request screen
    const eligible = this.completed_product_onboarding && this.completed_data_verification;
    if (eligible) {
      await this.$router.push({
        name: 'client-new-loan'
      });
    }
  },
  methods: {
    async fetchLoanProduct() {
      try {
        this.loading = true

        const response = await this.useJwt()
          .clientService
          .fetchLoanProduct(this.loan_product_id, {
            add_dynamic_field_options: true
          });
        this.loan_product = this.getValueFromSource(response, "data.data");
        console.log("loan_product", this.getValueFromSource(response, "data.data"))
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    async canRequestLoanProduct() {
      try {
        this.loading = true;

        const response = await this.useJwt()
          .clientService
          .canRequestLoanProduct(this.loan_product_id);

        const { can_request_loan } = response.data.data
        this.completed_product_onboarding = can_request_loan;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.errorMessage = error_message;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async isUserDataVerified() {
      try {
        this.loading = true;

        const response = await this.useJwt()
          .clientService
          .isUserDataVerified(this.loan_product_id);

        const { is_user_eligible } = response.data.data;
        this.completed_data_verification = is_user_eligible;

        await this.fetchLoanProduct();
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        console.log("error_message", error_message);
        this.errorMessage = error_message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error!",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: error_message,
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async finishOnBoarding() {
      await this.canRequestLoanProduct();
      if (this.loan_product && this.loan_product.specification !== 'nss') {
        await this.isUserDataVerified();
      } else {
        this.completed_data_verification = true
      }
    }
  }
}
</script>

<style lang="scss">
.wrapper {
  width: 80%;
  margin: auto;

  @media (max-width: 768px) {
    padding-top: 20px;
    margin-top: 20px;
    width: 90%;
  }

}

.transparent-pending-gif {
  display: none;
}

.dark-layout {
  .pending-gif {
    display: none;
  }

  .transparent-pending-gif {
    display: block !important;
  }

}
</style>
